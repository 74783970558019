<template>
    <v-menu ref="menu" v-model="open" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="formatedDate" :label="label" :placeholder="placeholder" :disabled="disabled" background-color="white" append-icon="far fa-calendar-alt" :hide-details="!failed" readonly outlined dense v-bind="attrs" v-on="on" clearable clear-icon="far fa-times-circle" @click:clear="clearInput()" :error-messages="errors" :class="classes">
                <template v-slot:append>
                    <v-icon v-on="on" color="grey" :disabled="disabled"> fas fa-calendar-alt </v-icon>
                </template>
            </v-text-field>
        </template>

        <v-date-picker v-model="date" @change="submit()" :type="type" :range="range" no-title scrollable>

        </v-date-picker>
    </v-menu>
</template>

<script>
import { format } from 'date-fns';

export default {
    name: 'DatePickerMenu',

    props: {
        value: { required: true },
        type: { default: 'date' },
        range: { default: false },
        label: { type: String },
        classes: { type: String },
        placeholder: { type: String },
        errors: { type: Array, required: false },
        failed: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },

    data: () => ({
        open: false,
        date: null
    }),

    computed: {
        formatedDate() {
            if (Array.isArray(this.date)) {
                let date = '';
                if (this.date[0]) {
                    date = this.$options.filters.toDate(this.date[0]);
                }
                if (this.date[1]) {
                    date = `${date} - ${this.$options.filters.toDate(this.date[1])}`;
                }
                return date;
            } else {
                if (!this.date) {
                    return '';
                } else {
                    return this.$options.filters.toDate(this.date);
                }
            }
        }
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                if (Array.isArray(this.value)) {
                    this.date = this.value.map((d) => format(new Date(d), 'yyyy-MM-dd HH:mm:ss'));
                } else if (this.value) {
                    this.date = format(new Date(this.value), 'yyyy-MM-dd HH:mm:ss');
                }
            }
        }
    },

    methods: {
        submit() {
            if (Array.isArray(this.date)) {
                const date = [
                    format(new Date(this.date[0] + ' 00:00:00'), 'yyyy-MM-dd HH:mm:ss'),
                    format(new Date(this.date[1] + ' 00:00:00'), 'yyyy-MM-dd HH:mm:ss')
                ];
                this.$emit('input', date);
            } else if(this.date) {
                const date = this.date + ' 00:00:00';
                this.$emit('input', format(new Date(date), 'yyyy-MM-dd HH:mm:ss'));
            } else {
                this.$emit('input', null);
            }
            this.open = false;
        },

        clearInput() {
            this.date = null;
            this.submit();
        }
    }
};
</script>